import React from "react"
import SchoolTextSection from "./SchoolTextSection"
import { useTranslation } from "react-i18next"


const SchoolItemScholarshipsSection = (props) => {
  const data = props.data || {}
  const { t } = useTranslation()

  return (
    <div className="white-block about-school-block">
      <div className="about-school-content-wrapper f-js container">
        {data.conditions ? <SchoolTextSection title={t("school.header.scholarships")} text={data.conditions} /> :
          <h2 className="info-text-wrapper" style={{ textAlign: "center", paddingTop: "74px", fontSize: "24px"
          }}>{t("school.scholarships.noInformation")}</h2>}
      </div>
    </div>
  )
}

export default SchoolItemScholarshipsSection
